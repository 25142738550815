import appStrings from '@/app/utility/string.utility';

export default {
  props: ['partyRowDetails'],
  data() {
    return {
      backgroundVerificationData: [],
      backgroundVerificationFields: [
        {
          key: 'creation_date'
          // class: 'col-fix text-center'
        },
        {
          key: 'status'
          // class: 'col-fix text-center'
        },
        {
          key: 'initiate'
          // class: 'col-fix text-center'
        }
      ],
      partyRequestId: null,
      showBackgroundVerificationModal: false,
      registeredAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      siteAddress: {
        bgvc_dtl_id: 0,
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      natureOfBusiness: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      bgvc_hdr_id: 0,
      bgvc_dtl_id1: 0,
      bgvc_dtl_id0: 0,
      checkAddress: false,
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      parentId: null,
      addressType: null,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      currentPage: 1
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyRequestId = this.partyRowDetails.req_id;
      this.getBackgroundVerificationData();
    }
  },
  methods: {
    getBackgroundVerificationData() {
      const payload = {
        _page: 0,
        _limit: 100,
        party_req_id: this.partyRequestId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.backgroundVerificationData = response.data.data.page;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    initiateBackgroundVerification(type) {
      if (type === 'create') {
        this.registeredAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.siteAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
        this.showBackgroundVerificationModal = true;
      } else {
        const payload = {
          bgVcHdrId: type.bgvc_hdr_id
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getBackgroundVerificationDetailsData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              let result = response.data.data.bgvc_dtls;
              this.registeredAddress = {
                bgvc_dtl_id: result[0].bgvc_dtl_id,
                add1: result[0].address1,
                add2: result[0].address2,
                country: result[0].country,
                state: result[0].state,
                city: result[0].city,
                pin: result[0].pincode
              };
              this.siteAddress = {
                bgvc_dtl_id: result[1].bgvc_dtl_id,
                add1: result[1].address1,
                add2: result[1].address2,
                country: result[1].country,
                state: result[1].state,
                city: result[1].city,
                pin: result[1].pincode
              };
              this.natureOfBusiness = result[1].nature_of_business;
              this.showBackgroundVerificationModal = true;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    saveBackgroundVerificationData(type) {
      const payload = {
        bgvc_hdr_id: this.bgvc_hdr_id,
        name: this.partyRowDetails.party_name,
        email: this.partyRowDetails.email,
        mobile_no: this.partyRowDetails.mob,
        pan_no: this.partyRowDetails.pan,
        party_req_id: this.partyRequestId,
        status: 'DRAFT',
        bgvc_dtls: [
          {
            bgvc_dtl_id: this.siteAddress.bgvc_dtl_id,
            address_type: 'REG',
            country: this.registeredAddress.country,
            state: this.registeredAddress.state,
            city: this.registeredAddress.city,
            pincode: this.registeredAddress.pin,
            address1: this.registeredAddress.add1,
            address2: this.registeredAddress.add2,
            nature_of_business: null
          },
          {
            bgvc_dtl_id: this.siteAddress.bgvc_dtl_id,
            address_type: 'SITE',
            country: this.siteAddress.country,
            state: this.siteAddress.state,
            city: this.siteAddress.city,
            pincode: this.siteAddress.pin,
            address1: this.siteAddress.add1,
            address2: this.siteAddress.add2,
            nature_of_business: this.natureOfBusiness
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/postBackgroundVerificationData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getBackgroundVerificationData();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
      if (type === 'saveClose') {
        this.showBackgroundVerificationModal = false;
      }
    },
    backgroundVerificationModal(flag) {
      this.showBackgroundVerificationModal = flag;
    },
    checkUncheckAddress() {
      if (this.checkAddress === 'checked') {
        this.siteAddress = {
          bgvc_dtl_id: 0,
          add1: this.registeredAddress.add1,
          add2: this.registeredAddress.add2,
          country: this.registeredAddress.country,
          state: this.registeredAddress.state,
          city: this.registeredAddress.city,
          pin: this.registeredAddress.pin
        };
      } else if (this.checkAddress === 'unchecked') {
        this.siteAddress = {
          bgvc_dtl_id: 0,
          add1: null,
          add2: null,
          country: null,
          state: null,
          city: null,
          pin: null
        };
      }
    },
    openValueSetModal(vsetCode, addressType) {
      this.vsetCode = vsetCode;
      this.addressType = addressType;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = this.parentId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        if (this.addressType === 'registered') {
          this.registeredAddress.country = item.country;
          this.parentId = item.country_id;
        } else if (this.addressType === 'site') {
          this.siteAddress.country = item.country;
          this.parentId = item.country_id;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        if (this.addressType === 'registered') {
          this.registeredAddress.state = item.state;
        } else if (this.addressType === 'site') {
          this.siteAddress.state = item.state;
        }
      }
    },
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: 446,
        sub_menu_id: null,
        record_id: this.rfpHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  }
};
